import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Conf from '../../config.json';
import ErrorBar from '../ErrorBar/ErrorBar';


function Star() {
    return (<span style={{ color: "#E60000" }}>*</span>);
}

const sxFormLabel = { color: "#08081B", fontSize: 16, fontWeight: 700, mb: 1 }

function CurrentObligations({ props }) {
    const [error, setError] = React.useState("");

    const handleNext = (ev) => {
        if (!props.currentObligationsValidated) {
            setError("Please complete mandatory fields");
        } else {
            props.updateBursar();
            props.setExpanded(7);
        }
    };
    return (
        <Box sx={{ backgroundColor: "#FFFFFF", padding: 2, borderRadius: 2, mt: 3, boxShadow: 3 }}>
            {error &&
                <ErrorBar props={{ error: error, setError: setError }} />
            }
            <Grid container columns={16} sx={{ color: "#282828" }}>
                <Grid item sm={8} sx={{ textAlign: "left" }}>
                    <Typography sx={{ fontSize: 18, fontWeight: 500 }}>
                        Current Obligations
                    </Typography>
                </Grid>
                <Grid item sm={8} sx={{ textAlign: "right" }}>
                    <Typography sx={{ color: "#666666", fontSize: 16, fontWeight: 500 }}>
                        Section 6 of {props.sections}
                    </Typography>
                </Grid>
            </Grid>
            <Divider sx={{ mt: 3, mb: 3 }} />
            <Grid container columns={16} spacing={3} padding={2}>
                <Grid item sm={8} sx={{width: "100%"}}>
                    <FormControl fullWidth>
                        <FormLabel id="label-householdincome" sx={sxFormLabel}>Combined Household Income<Star /></FormLabel>
                        <Select
                            labelId="label-householdincome"
                            value={props.householdincome}
                            error={props.householdincome === "none"}
                            onChange={(ev) => { props.setHouseholdincome(ev.target.value) }}
                        >
                            <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                            {Conf.lists.income.map((year, index) => (
                                <MenuItem key={index} value={year}>{year}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={8} sx={{width: "100%"}}>
                    <FormControl fullWidth>
                        <FormLabel id="label-fundedby" sx={sxFormLabel}>How are your studies currently funded?<Star /></FormLabel>
                        <Select
                            labelId="label-fundedby"
                            value={props.fundedby}
                            error={props.fundedby === "none"}
                            onChange={(ev) => { props.setFundedby(ev.target.value) }}
                        >
                            <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                            {Conf.lists.funded.map((year, index) => (
                                <MenuItem key={index} value={year}>{year}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                {(props.fundedby === "Bursary") &&
                    <Grid item sm={8} sx={{width: "100%"}}>
                        <FormControl fullWidth>
                            <FormLabel id="label-bursaryname" sx={sxFormLabel}>What is the name of your bursary?<Star /></FormLabel>
                            <TextField
                                required
                                fullWidth
                                error={props.bursaryname ? false : true}
                                label="Enter bursary name"
                                value={props.bursaryname}
                                onChange={(ev) => { props.setBursaryname(ev.target.value) }}
                            />
                        </FormControl>
                    </Grid>
                }
                {(props.fundedby === "Bursary") &&
                    <Grid item sm={8} sx={{width: "100%"}}>
                        <FormControl fullWidth>
                            <FormLabel id="label-bursaryobligation" sx={sxFormLabel}>Does it carry any employment obligations?<Star /></FormLabel>
                            <Select
                                labelId="label-bursaryobligation"
                                value={props.bursaryobligation}
                                error={props.bursaryobligation === "none"}
                                onChange={(ev) => { props.setBursaryobligation(ev.target.value) }}
                            >
                                <MenuItem key={99} value={"none"} disabled>{"-Select-"}</MenuItem>
                                <MenuItem key={0} value={"No"}>No</MenuItem>
                                <MenuItem key={1} value={"Yes"}>Yes</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                }
            </Grid>
            <Box sx={{ width: "100%" }} align="right">
                <Button
                    variant="filled"
                    sx={{ mr: 3 }} style={{ borderRadius: 68, width: 100, fontSize: 16, fontWeight: 700, backgroundColor: 'black', color: 'white' }}
                    onClick={handleNext}
                >
                    Next
                </Button>
            </Box>
        </Box>
    );
}


export default CurrentObligations;