import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import Conf from '../../config.json';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';
import PersonalDetails from '../Sections/PersonalDetails';
import ContactDetails from '../Sections/ContactDetails';
import EducationalDetails from '../Sections/EducationalDetails';
import LeadershipRoles from '../Sections/LeadershipRoles';
import EmergencyContacts from '../Sections/EmergencyContacts';
import CurrentObligations from '../Sections/CurrentObligations';
import SupportingDocuments from '../Sections/SupportingDocuments';
import Summary from '../Sections/Summary';


const theme = createTheme({
    palette: {
        primary: {
            main: '#393949',
        }
    },
    typography: {
        button: {
            textTransform: 'none'
        }
    }
});

const average = (array) => {
    if (!array.length) return 0;
    var sum = 0;
    for (var i = 0; i < array.length; i++) sum += parseInt(array[i]);
    if (!sum) return 0;
    return parseInt(sum / (array.length));
};

function ApplicationForm({ props }) {
    const [user, setUser] = React.useState("");
    const [expanded, setExpanded] = React.useState(0);
    const [addline1, setAddline1] = React.useState("");
    const [addline2, setAddline2] = React.useState("");
    const [city, setCity] = React.useState("");
    const [suburb, setSuburb] = React.useState("");
    const [province, setProvince] = React.useState("none");
    const [postcode, setPostcode] = React.useState("");
    const [gender, setGender] = React.useState("none");
    const [nationality, setNationality] = React.useState("");
    const [race, setRace] = React.useState("none");
    const [homelanguage, setHomelanguage] = React.useState("none");
    const [otherlanguage, setOtherlanguage] = React.useState("none");
    const [altname, setAltname] = React.useState("");
    const [altmobile, setAltmobile] = React.useState("");
    const [altrelation, setAltrelation] = React.useState("");
    const [matriculated, setMatriculated] = React.useState("none");
    const [highschoolprovince, setHighschoolprovince] = React.useState("none");
    const [highschoolname, setHighschoolname] = React.useState("");
    const [yearmatriculated, setYearmatriculated] = React.useState("none");
    const [tertiaryinstitution, setTertiaryinstitution] = React.useState("none");
    const [tertiaryinstitutionname, setTertiaryinstitutionname] = React.useState("none");
    const [otherinstitutionname, setOtherinstitutionname] = React.useState("");
    const [levelstudy, setLevelstudy] = React.useState("none");
    const [yearstudy, setYearstudy] = React.useState("none");
    const [studentnumber, setStudentnumber] = React.useState("");
    const [gr11subj1, setGr11subj1] = React.useState("none");
    const [gr11mark1, setGr11mark1] = React.useState(0);
    const [gr12subj1, setGr12subj1] = React.useState("none");
    const [gr12mark1, setGr12mark1] = React.useState(0);
    const [gr11subj2, setGr11subj2] = React.useState("none");
    const [gr11mark2, setGr11mark2] = React.useState(0);
    const [gr12subj2, setGr12subj2] = React.useState("none");
    const [gr12mark2, setGr12mark2] = React.useState(0);
    const [gr11subj3, setGr11subj3] = React.useState("none");
    const [gr11mark3, setGr11mark3] = React.useState(0);
    const [gr12subj3, setGr12subj3] = React.useState("none");
    const [gr12mark3, setGr12mark3] = React.useState(0);
    const [gr11subj4, setGr11subj4] = React.useState("none");
    const [gr11mark4, setGr11mark4] = React.useState(0);
    const [gr12subj4, setGr12subj4] = React.useState("none");
    const [gr12mark4, setGr12mark4] = React.useState(0);
    const [gr11subj5, setGr11subj5] = React.useState("none");
    const [gr11mark5, setGr11mark5] = React.useState(0);
    const [gr12subj5, setGr12subj5] = React.useState("none");
    const [gr12mark5, setGr12mark5] = React.useState(0);
    const [gr11subj6, setGr11subj6] = React.useState("none");
    const [gr11mark6, setGr11mark6] = React.useState(0);
    const [gr12subj6, setGr12subj6] = React.useState("none");
    const [gr12mark6, setGr12mark6] = React.useState(0);
    const [gr11subj7, setGr11subj7] = React.useState("none");
    const [gr11mark7, setGr11mark7] = React.useState(0);
    const [gr12subj7, setGr12subj7] = React.useState("none");
    const [gr12mark7, setGr12mark7] = React.useState(0);
    const [gr11subj8, setGr11subj8] = React.useState("none");
    const [gr11mark8, setGr11mark8] = React.useState(0);
    const [gr12subj8, setGr12subj8] = React.useState("none");
    const [gr12mark8, setGr12mark8] = React.useState(0);
    const [gr11subj9, setGr11subj9] = React.useState("none");
    const [gr11mark9, setGr11mark9] = React.useState(0);
    const [gr12subj9, setGr12subj9] = React.useState("none");
    const [gr12mark9, setGr12mark9] = React.useState(0);
    const [gr11subj10, setGr11subj10] = React.useState("none");
    const [gr11mark10, setGr11mark10] = React.useState(0);
    const [gr12subj10, setGr12subj10] = React.useState("none");
    const [gr12mark10, setGr12mark10] = React.useState(0);
    const [tersubj1, setTersubj1] = React.useState("");
    const [termark1, setTermark1] = React.useState(0);
    const [tersubj2, setTersubj2] = React.useState("");
    const [termark2, setTermark2] = React.useState(0);
    const [tersubj3, setTersubj3] = React.useState("");
    const [termark3, setTermark3] = React.useState(0);
    const [tersubj4, setTersubj4] = React.useState("");
    const [termark4, setTermark4] = React.useState(0);
    const [tersubj5, setTersubj5] = React.useState("");
    const [termark5, setTermark5] = React.useState(0);
    const [tersubj6, setTersubj6] = React.useState("");
    const [termark6, setTermark6] = React.useState(0);
    const [extramuralActivities, setExtramuralActivities] = React.useState("");
    const [achievements, setAchievements] = React.useState("");
    const [careerMotivation, setCareerMotivation] = React.useState("");
    const [emergencycontactname, setEmergencycontactname] = React.useState("");
    const [emergencycontactrelationship, setEmergencycontactrelationship] = React.useState("");
    const [emergencycontactnumber, setEmergencycontactnumber] = React.useState("");
    const [emergencycontactdisability, setEmergencycontactdisability] = React.useState("none");
    const [emergencycontactconvicted, setEmergencycontactconvicted] = React.useState("none");
    const [emergencycontactconvicteddescn, setEmergencycontactconvicteddescn] = React.useState("");
    const [emergencycontactdisabilitydesc, setEmergencycontactdisabilitydesc] = React.useState("");
    const [householdincome, setHouseholdincome] = React.useState("none");
    const [fundedby, setFundedby] = React.useState("none");
    const [bursaryname, setBursaryname] = React.useState("");
    const [bursaryobligation, setBursaryobligation] = React.useState("none");
    const [fileAcademicRecord, setFileAcademicRecord] = React.useState("");
    const [fileAcceptance, setFileAcceptance] = React.useState("");
    const [fileFeeStatement, setFileFeeStatement] = React.useState("");
    const [fileGrade11, setFileGrade11] = React.useState("");
    const [fileGrade12, setFileGrade12] = React.useState("");
    const [fileId, setFileId] = React.useState("");
    const [fileProfilePic, setFileProfilePic] = React.useState("");
    const [fileProofRegistration, setFileProofRegistration] = React.useState("");
    const [fileProofConsent, setFileProofConsent] = React.useState("");
    const [gr11avg, setGr11avg] = React.useState(0);
    const [gr12avg, setGr12avg] = React.useState(0);
    const [teravg, setTeravg] = React.useState(0);
    const [gr11subjcnt, setGr11subjcnt] = React.useState(0);
    const [gr12subjcnt, setGr12subjcnt] = React.useState(0);
    const [tersubjcnt, setTersubjcnt] = React.useState(0);
    const [personalDetailsValidated, setPersonalDetailsValidated] = React.useState(false);
    const [contactDetailsValidated, setContactDetailsValidated] = React.useState(false);
    const [educationalDetailsValidated, setEducationalDetailsValidated] = React.useState(false);
    const [leadershipRolesValidated, setLeadershipRolesValidated] = React.useState(false);
    const [emergencyContactsValidated, setEmergencyContactsValidated] = React.useState(false);
    const [currentObligationsValidated, setCurrentObligationsValidated] = React.useState(false);
    const [supportingDocumentsValidated, setSupportingDocumentsValidated] = React.useState(false);
    const [allValidated, setAllValidated] = React.useState(false);
    const [populated, setPopulated] = React.useState(false);

    const formFields = {
        addline1: addline1, addline2: addline2,
        city: city, suburb: suburb, province: province,
        postcode: postcode, gender: gender,
        nationality: nationality, race: race,
        homelanguage: homelanguage, otherlanguage: otherlanguage,
        altname: altname, altmobile: altmobile, altrelation: altrelation,
        matriculated: matriculated, highschool_province: highschoolprovince,
        highschool_name: highschoolname, yearMatriculated: yearmatriculated,
        tertiary_institution: tertiaryinstitution,
        tertiary_institution_name: tertiaryinstitutionname,
        other_institution_name: otherinstitutionname,
        level_study: levelstudy, year_study: yearstudy,
        student_number: studentnumber,
        gr11_subj1: gr11subj1, gr12_subj1: gr12subj1,
        gr11_subj2: gr11subj2, gr12_subj2: gr12subj2,
        gr11_subj3: gr11subj3, gr12_subj3: gr12subj3,
        gr11_subj4: gr11subj4, gr12_subj4: gr12subj4,
        gr11_subj5: gr11subj5, gr12_subj5: gr12subj5,
        gr11_subj6: gr11subj6, gr12_subj6: gr12subj6,
        gr11_subj7: gr11subj7, gr12_subj7: gr12subj7,
        gr11_subj8: gr11subj8, gr12_subj8: gr12subj8,
        gr11_subj9: gr11subj9, gr12_subj9: gr12subj9,
        gr11_subj10: gr11subj10, gr12_subj10: gr12subj10,
        gr11_mark1: gr11mark1, gr11_mark2: gr11mark2,
        gr11_mark3: gr11mark3, gr11_mark4: gr11mark4,
        gr11_mark5: gr11mark5, gr11_mark6: gr11mark6,
        gr11_mark7: gr11mark7, gr11_mark8: gr11mark8,
        gr11_mark9: gr11mark9, gr11_mark10: gr11mark10,
        gr12_mark1: gr12mark1, gr12_mark2: gr12mark2,
        gr12_mark3: gr12mark3, gr12_mark4: gr12mark4,
        gr12_mark5: gr12mark5, gr12_mark6: gr12mark6,
        gr12_mark7: gr12mark7, gr12_mark8: gr12mark8,
        gr12_mark9: gr12mark9, gr12_mark10: gr12mark10,
        terSubject_1: tersubj1, terMark_1: termark1,
        terSubject_2: tersubj2, terMark_2: termark2,
        terSubject_3: tersubj3, terMark_3: termark3,
        terSubject_4: tersubj4, terMark_4: termark4,
        terSubject_5: tersubj5, terMark_5: termark5,
        terSubject_6: tersubj6, terMark_6: termark6,
        extramural: extramuralActivities,
        achievements: achievements,
        careermotiv: careerMotivation,
        emergencycontact_name: emergencycontactname,
        emergencycontact_relation: emergencycontactrelationship,
        emergencycontact_no: emergencycontactnumber,
        emergencycontact_disability: emergencycontactdisability,
        emergencycontact_convicted: emergencycontactconvicted,
        emergencycontact_convicted_desc: emergencycontactconvicteddescn,
        emergencycontact_disability_desc: emergencycontactdisabilitydesc,
        household_income: householdincome,
        fundedby: fundedby,
        bursary_name: bursaryname,
        bursary_obligation: bursaryobligation,
        file_AcademicRecord: fileAcademicRecord,
        file_Acceptance: fileAcceptance,
        file_FeeStatement: fileFeeStatement,
        file_Grade_11: fileGrade11,
        file_Grade_12: fileGrade12,
        file_ID: fileId,
        file_profilePic: fileProfilePic,
        file_ProofRegistration: fileProofRegistration,
        file_ProofConsent: fileProofConsent
    };

    const save = (payload) => {
        if (!payload) return;
        const endpoint = `${Conf.api.applicant_api}/bursar`;
        const apiKey = Conf.api.xapikey;
        const config = {
            headers: {
                "x-api-key": apiKey,
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
            }
        };
        props.setWaiting(true);
        axios.patch(endpoint, payload, config)
            .then(function (response) {
                if (response.status === 200) {
                    props.setError("");
                    setPopulated(false);
                    props.reloadUser(true);
                    if (payload.status === "Submitted") {
                        props.setCurrentPage("Submitted");
                    }
                }
                props.setWaiting(false);
            })
            .catch(function (error) {
                console.log(error);
                props.setWaiting(false);
                props.setError(error.response.data.message);
            });
    }

    const handleSave = (ev) => {
        save(formFields);
        setExpanded(99);
    };

    const updateBursar = () => {
        save(formFields);
    };

    const handleSubmit = (ev) => {
        const payload = {
            ...formFields,
            status: "Submitted"
        }
        save(payload);
    };

    const formProps = {
        user: user,
        sections: 7,
        addline1: addline1,
        addline2: addline2,
        city: city,
        suburb: suburb,
        province: province,
        postcode: postcode,
        gender: gender,
        nationality: nationality,
        race: race,
        homelanguage: homelanguage,
        otherlanguage: otherlanguage,
        altname: altname,
        altmobile: altmobile,
        altrelation: altrelation,
        matriculated: matriculated,
        highschoolprovince: highschoolprovince,
        highschoolname: highschoolname,
        yearmatriculated: yearmatriculated,
        tertiaryinstitution: tertiaryinstitution,
        tertiaryinstitutionname: tertiaryinstitutionname,
        otherinstitutionname: otherinstitutionname,
        levelstudy: levelstudy,
        yearstudy: yearstudy,
        studentnumber: studentnumber,
        gr11subj1: gr11subj1,
        gr11mark1: gr11mark1,
        gr12subj1: gr12subj1,
        gr12mark1: gr12mark1,
        gr11subj2: gr11subj2,
        gr11mark2: gr11mark2,
        gr12subj2: gr12subj2,
        gr12mark2: gr12mark2,
        gr11subj3: gr11subj3,
        gr11mark3: gr11mark3,
        gr12subj3: gr12subj3,
        gr12mark3: gr12mark3,
        gr11subj4: gr11subj4,
        gr11mark4: gr11mark4,
        gr12subj4: gr12subj4,
        gr12mark4: gr12mark4,
        gr11subj5: gr11subj5,
        gr11mark5: gr11mark5,
        gr12subj5: gr12subj5,
        gr12mark5: gr12mark5,
        gr11subj6: gr11subj6,
        gr11mark6: gr11mark6,
        gr12subj6: gr12subj6,
        gr12mark6: gr12mark6,
        gr11subj7: gr11subj7,
        gr11mark7: gr11mark7,
        gr12subj7: gr12subj7,
        gr12mark7: gr12mark7,
        gr11subj8: gr11subj8,
        gr11mark8: gr11mark8,
        gr12subj8: gr12subj8,
        gr12mark8: gr12mark8,
        gr11subj9: gr11subj9,
        gr11mark9: gr11mark9,
        gr12subj9: gr12subj9,
        gr12mark9: gr12mark9,
        gr11subj10: gr11subj10,
        gr11mark10: gr11mark10,
        gr12subj10: gr12subj10,
        gr12mark10: gr12mark10,
        tersubj1: tersubj1,
        termark1: termark1,
        tersubj2: tersubj2,
        termark2: termark2,
        tersubj3: tersubj3,
        termark3: termark3,
        tersubj4: tersubj4,
        termark4: termark4,
        tersubj5: tersubj5,
        termark5: termark5,
        tersubj6: tersubj6,
        termark6: termark6,
        extramuralActivities: extramuralActivities,
        achievements: achievements,
        careerMotivation: careerMotivation,
        emergencycontactname: emergencycontactname,
        emergencycontactrelationship: emergencycontactrelationship,
        emergencycontactnumber: emergencycontactnumber,
        emergencycontactdisability: emergencycontactdisability,
        emergencycontactconvicted: emergencycontactconvicted,
        emergencycontactconvicteddescn: emergencycontactconvicteddescn,
        emergencycontactdisabilitydesc: emergencycontactdisabilitydesc,
        householdincome: householdincome,
        fundedby: fundedby,
        bursaryname: bursaryname,
        bursaryobligation: bursaryobligation,
        fileAcademicRecord: fileAcademicRecord,
        fileAcceptance: fileAcceptance,
        fileFeeStatement: fileFeeStatement,
        fileGrade11: fileGrade11,
        fileGrade12: fileGrade12,
        fileId: fileId,
        fileProfilePic: fileProfilePic,
        fileProofRegistration: fileProofRegistration,
        fileProofConsent: fileProofConsent,
        gr11avg: gr11avg,
        gr12avg: gr12avg,
        teravg: teravg,
        personalDetailsValidated: personalDetailsValidated,
        contactDetailsValidated: contactDetailsValidated,
        currentObligationsValidated: currentObligationsValidated,
        educationalDetailsValidated: educationalDetailsValidated,
        leadershipRolesValidated: leadershipRolesValidated,
        supportingDocumentsValidated: supportingDocumentsValidated,
        emergencyContactsValidated: emergencyContactsValidated,
        setAddline1: setAddline1,
        setAddline2: setAddline2,
        setCity: setCity,
        setSuburb: setSuburb,
        setProvince: setProvince,
        setPostcode: setPostcode,
        setGender: setGender,
        setNationality: setNationality,
        setRace: setRace,
        setHomelanguage: setHomelanguage,
        setOtherlanguage: setOtherlanguage,
        setAltname: setAltname,
        setAltmobile: setAltmobile,
        setAltrelation: setAltrelation,
        setMatriculated: setMatriculated,
        setHighschoolprovince: setHighschoolprovince,
        setHighschoolname: setHighschoolname,
        setYearmatriculated: setYearmatriculated,
        setTertiaryinstitution: setTertiaryinstitution,
        setTertiaryinstitutionname: setTertiaryinstitutionname,
        setOtherinstitutionname: setOtherinstitutionname,
        setLevelstudy: setLevelstudy,
        setYearstudy: setYearstudy,
        setStudentnumber: setStudentnumber,
        setGr11subj1: setGr11subj1,
        setGr11mark1: setGr11mark1,
        setGr12subj1: setGr12subj1,
        setGr12mark1: setGr12mark1,
        setGr11subj2: setGr11subj2,
        setGr11mark2: setGr11mark2,
        setGr12subj2: setGr12subj2,
        setGr12mark2: setGr12mark2,
        setGr11subj3: setGr11subj3,
        setGr11mark3: setGr11mark3,
        setGr12subj3: setGr12subj3,
        setGr12mark3: setGr12mark3,
        setGr11subj4: setGr11subj4,
        setGr11mark4: setGr11mark4,
        setGr12subj4: setGr12subj4,
        setGr12mark4: setGr12mark4,
        setGr11subj5: setGr11subj5,
        setGr11mark5: setGr11mark5,
        setGr12subj5: setGr12subj5,
        setGr12mark5: setGr12mark5,
        setGr11subj6: setGr11subj6,
        setGr11mark6: setGr11mark6,
        setGr12subj6: setGr12subj6,
        setGr12mark6: setGr12mark6,
        setGr11subj7: setGr11subj7,
        setGr11mark7: setGr11mark7,
        setGr12subj7: setGr12subj7,
        setGr12mark7: setGr12mark7,
        setGr11subj8: setGr11subj8,
        setGr11mark8: setGr11mark8,
        setGr12subj8: setGr12subj8,
        setGr12mark8: setGr12mark8,
        setGr11subj9: setGr11subj9,
        setGr11mark9: setGr11mark9,
        setGr12subj9: setGr12subj9,
        setGr12mark9: setGr12mark9,
        setGr11subj10: setGr11subj10,
        setGr11mark10: setGr11mark10,
        setGr12subj10: setGr12subj10,
        setGr12mark10: setGr12mark10,
        setTersubj1: setTersubj1,
        setTermark1: setTermark1,
        setTersubj2: setTersubj2,
        setTermark2: setTermark2,
        setTersubj3: setTersubj3,
        setTermark3: setTermark3,
        setTersubj4: setTersubj4,
        setTermark4: setTermark4,
        setTersubj5: setTersubj5,
        setTermark5: setTermark5,
        setTersubj6: setTersubj6,
        setTermark6: setTermark6,
        setExtramuralActivities: setExtramuralActivities,
        setAchievements: setAchievements,
        setCareerMotivation: setCareerMotivation,
        setEmergencycontactname: setEmergencycontactname,
        setEmergencycontactrelationship: setEmergencycontactrelationship,
        setEmergencycontactnumber: setEmergencycontactnumber,
        setEmergencycontactdisability: setEmergencycontactdisability,
        setEmergencycontactconvicted: setEmergencycontactconvicted,
        setEmergencycontactconvicteddescn: setEmergencycontactconvicteddescn,
        setEmergencycontactdisabilitydesc: setEmergencycontactdisabilitydesc,
        setHouseholdincome: setHouseholdincome,
        setFundedby: setFundedby,
        setBursaryname: setBursaryname,
        setBursaryobligation: setBursaryobligation,
        setFileAcademicRecord: setFileAcademicRecord,
        setFileAcceptance: setFileAcceptance,
        setFileFeeStatement: setFileFeeStatement,
        setFileGrade11: setFileGrade11,
        setFileGrade12: setFileGrade12,
        setFileId: setFileId,
        setFileProfilePic: setFileProfilePic,
        setFileProofRegistration: setFileProofRegistration,
        setFileProofConsent: setFileProofConsent,
        updateBursar: updateBursar,
        setError: props.setError,
        setWaiting: props.setWaiting,
        setExpanded: setExpanded
    };


    React.useEffect(() => {
        setUser(props.user);

        var gr11marks = [];
        var gr12marks = [];
        var termarks = [];

        if (gr11subj1 !== "none") {
            gr11marks.push(parseInt(gr11mark1))
        }
        if (gr11subj2 !== "none") {
            gr11marks.push(parseInt(gr11mark2));
        }
        if (gr11subj3 !== "none") {
            gr11marks.push(parseInt(gr11mark3));
        }
        if (gr11subj4 !== "none") {
            gr11marks.push(parseInt(gr11mark4))
        }
        if (gr11subj5 !== "none") {
            gr11marks.push(parseInt(gr11mark5))
        }
        if (gr11subj6 !== "none") {
            gr11marks.push(parseInt(gr11mark6));
        }
        if (gr11subj7 !== "none") {
            gr11marks.push(parseInt(gr11mark7));
        }
        if (gr11subj8 !== "none") {
            gr11marks.push(parseInt(gr11mark8));
        }
        if (gr11subj9 !== "none") {
            gr11marks.push(parseInt(gr11mark9));
        }
        if (gr11subj10 !== "none") {
            gr11marks.push(parseInt(gr11mark10));
        }
        if (gr12subj1 !== "none") {
            gr12marks.push(parseInt(gr12mark1));
        }
        if (gr12subj2 !== "none") {
            gr12marks.push(parseInt(gr12mark2));
        }
        if (gr12subj3 !== "none") {
            gr12marks.push(parseInt(gr12mark3))
        }
        if (gr12subj4 !== "none") {
            gr12marks.push(parseInt(gr12mark4));
        }
        if (gr12subj5 !== "none") {
            gr12marks.push(parseInt(gr12mark5));
        }
        if (gr12subj6 !== "none") {
            gr12marks.push(parseInt(gr12mark6));
        }
        if (gr12subj7 !== "none") {
            gr12marks.push(parseInt(gr12mark7));
        }
        if (gr12subj8 !== "none") {
            gr12marks.push(parseInt(gr12mark8));
        }
        if (gr12subj9 !== "none") {
            gr12marks.push(parseInt(gr12mark9));
        }
        if (gr12subj10 !== "none") {
            gr12marks.push(parseInt(gr12mark10));
        }
        if (tersubj1) {
            termarks.push(parseInt(termark1));
        }
        if (tersubj2) {
            termarks.push(parseInt(termark2));
        }
        if (tersubj3) {
            termarks.push(parseInt(termark3));
        }
        if (tersubj4) {
            termarks.push(parseInt(termark4));
        }
        if (tersubj5) {
            termarks.push(parseInt(termark5));
        }
        if (tersubj6) {
            termarks.push(parseInt(termark6));
        }
        setGr11avg(parseInt(average(gr11marks)));
        setGr12avg(parseInt(average(gr12marks)));
        setTeravg(parseInt(average(termarks)));
        setGr11subjcnt(gr11marks.length);
        setGr12subjcnt(gr12marks.length);
        setTersubjcnt(termarks.length);
        if ((Object.keys(user).length) && !populated) {
            if (user.addline1 && !addline1) setAddline1(user.addline1);
            if (user.addline2 && !addline2) setAddline2(user.addline2);
            if (user.city && !city) setCity(user.city);
            if (user.suburb && !suburb) setSuburb(user.suburb);
            if (user.province && province === "none") setProvince(user.province);
            if (user.postcode && !postcode) setPostcode(user.postcode);
            if (user.gender && gender === "none") setGender(user.gender);
            if (user.nationality && !nationality) setNationality(user.nationality);
            if (user.race && race === "none") setRace(user.race);
            if (user.homelanguage && homelanguage === "none") setHomelanguage(user.homelanguage);
            if (user.otherlanguage && otherlanguage === "none") setOtherlanguage(user.otherlanguage);
            if (user.altname && !altname) setAltname(user.altname);
            if (user.altrelation && !altrelation) setAltrelation(user.altrelation);
            if (user.altmobile && !altmobile) setAltmobile(user.altmobile);
            if (user.matriculated && matriculated === "none") setMatriculated(user.matriculated);
            if (user.highschool_province && highschoolprovince === "none") setHighschoolprovince(user.highschool_province);
            if (user.highschool_name && !highschoolname) setHighschoolname(user.highschool_name);
            if (user.yearMatriculated && yearmatriculated === "none") setYearmatriculated(user.yearMatriculated);
            if (user.tertiary_institution && tertiaryinstitution === "none") setTertiaryinstitution(user.tertiary_institution);
            if (user.tertiary_institution_name && tertiaryinstitutionname === "none") setTertiaryinstitutionname(user.tertiary_institution_name);
            if (user.other_institution_name && !otherinstitutionname) setOtherinstitutionname(user.other_institution_name);
            if (user.level_study && levelstudy === "none") setLevelstudy(user.level_study);
            if (user.year_study && yearstudy === "none") setYearstudy(user.year_study);
            if (user.student_number && !studentnumber) setStudentnumber(user.student_number);
            if (user.gr11_subj1 && gr11subj1 === "none") setGr11subj1(user.gr11_subj1);
            if (user.gr12_subj1 && gr12subj1 === "none") setGr12subj1(user.gr12_subj1);
            if (user.gr11_mark1 && !gr11mark1) setGr11mark1(user.gr11_mark1);
            if (user.gr12_mark1 && !gr12mark1) setGr12mark1(user.gr12_mark1);
            if (user.gr11_subj2 && gr11subj2 === "none") setGr11subj2(user.gr11_subj2);
            if (user.gr12_subj2 && gr12subj2 === "none") setGr12subj2(user.gr12_subj2);
            if (user.gr11_mark2 && !gr11mark2) setGr11mark2(user.gr11_mark2);
            if (user.gr12_mark2 && !gr12mark2) setGr12mark2(user.gr12_mark2);
            if (user.gr11_subj3 && gr11subj3 === "none") setGr11subj3(user.gr11_subj3);
            if (user.gr12_subj3 && gr12subj3 === "none") setGr12subj3(user.gr12_subj3);
            if (user.gr11_mark3 && !gr11mark3) setGr11mark3(user.gr11_mark3);
            if (user.gr12_mark3 && !gr12mark3) setGr12mark3(user.gr12_mark3);
            if (user.gr11_subj4 && gr11subj4 === "none") setGr11subj4(user.gr11_subj4);
            if (user.gr12_subj4 && gr12subj4 === "none") setGr12subj4(user.gr12_subj4);
            if (user.gr11_mark4 && !gr11mark4) setGr11mark4(user.gr11_mark4);
            if (user.gr12_mark4 && !gr12mark4) setGr12mark4(user.gr12_mark4);
            if (user.gr11_subj5 && gr11subj5 === "none") setGr11subj5(user.gr11_subj5);
            if (user.gr12_subj5 && gr12subj5 === "none") setGr12subj5(user.gr12_subj5);
            if (user.gr11_mark5 && !gr11mark5) setGr11mark5(user.gr11_mark5);
            if (user.gr12_mark5 && !gr12mark5) setGr12mark5(user.gr12_mark5);
            if (user.gr11_subj6 && gr11subj6 === "none") setGr11subj6(user.gr11_subj6);
            if (user.gr12_subj6 && gr12subj6 === "none") setGr12subj6(user.gr12_subj6);
            if (user.gr11_mark6 && !gr11mark6) setGr11mark6(user.gr11_mark6);
            if (user.gr12_mark6 && !gr12mark6) setGr12mark6(user.gr12_mark6);
            if (user.gr11_subj7 && gr11subj7 === "none") setGr11subj7(user.gr11_subj7);
            if (user.gr12_subj7 && gr12subj7 === "none") setGr12subj7(user.gr12_subj7);
            if (user.gr11_mark7 && !gr11mark7) setGr11mark7(user.gr11_mark7);
            if (user.gr12_mark7 && !gr12mark7) setGr12mark7(user.gr12_mark7);
            if (user.gr11_subj8 && gr11subj8 === "none") setGr11subj8(user.gr11_subj8);
            if (user.gr12_subj8 && gr12subj8 === "none") setGr12subj8(user.gr12_subj8);
            if (user.gr11_mark8 && !gr11mark8) setGr11mark8(user.gr11_mark8);
            if (user.gr12_mark8 && !gr12mark8) setGr12mark8(user.gr12_mark8);
            if (user.gr11_subj9 && gr11subj9 === "none") setGr11subj9(user.gr11_subj9);
            if (user.gr12_subj9 && gr12subj9 === "none") setGr12subj9(user.gr12_subj9);
            if (user.gr11_mark9 && !gr11mark9) setGr11mark9(user.gr11_mark9);
            if (user.gr12_mark9 && !gr12mark9) setGr12mark9(user.gr12_mark9);
            if (user.gr11_subj10 && gr11subj10 === "none") setGr11subj10(user.gr11_subj10);
            if (user.gr12_subj10 && gr12subj10 === "none") setGr12subj10(user.gr12_subj10);
            if (user.gr11_mark10 && !gr11mark10) setGr11mark10(user.gr11_mark10);
            if (user.gr12_mark10 && !gr12mark10) setGr12mark10(user.gr12_mark10);
            if (user.terMark_1 && !termark1) setTermark1(user.terMark_1);
            if (user.terSubject_1 && !tersubj1) setTersubj1(user.terSubject_1);
            if (user.terMark_2 && !termark2) setTermark2(user.terMark_2);
            if (user.terSubject_2 && !tersubj2) setTersubj2(user.terSubject_2);
            if (user.terMark_3 && !termark3) setTermark3(user.terMark_3);
            if (user.terSubject_3 && !tersubj3) setTersubj3(user.terSubject_3);
            if (user.terMark_4 && !termark4) setTermark4(user.terMark_4);
            if (user.terSubject_4 && !tersubj4) setTersubj4(user.terSubject_4);
            if (user.terMark_5 && !termark5) setTermark5(user.terMark_5);
            if (user.terSubject_5 && !tersubj5) setTersubj5(user.terSubject_5);
            if (user.terMark_6 && !termark6) setTermark6(user.terMark_6);
            if (user.terSubject_6 && !tersubj6) setTersubj6(user.terSubject_6);
            if (user.extramural && !extramuralActivities) setExtramuralActivities(user.extramural);
            if (user.achievements && !achievements) setAchievements(user.achievements);
            if (user.careermotiv && !careerMotivation) setCareerMotivation(user.careermotiv);
            if (user.emergencycontact_name && !emergencycontactname) setEmergencycontactname(user.emergencycontact_name);
            if (user.emergencycontact_relation && !emergencycontactrelationship) setEmergencycontactrelationship(user.emergencycontact_relation);
            if (user.emergencycontact_no && !emergencycontactnumber) setEmergencycontactnumber(user.emergencycontact_no);
            if (user.emergencycontact_disability && emergencycontactdisability === "none") setEmergencycontactdisability(user.emergencycontact_disability);
            if (user.emergencycontact_convicted && emergencycontactconvicted === "none") setEmergencycontactconvicted(user.emergencycontact_convicted);
            if (user.emergencycontact_convicted_desc && !emergencycontactconvicteddescn) setEmergencycontactconvicteddescn(user.emergencycontact_convicted_desc);
            if (user.emergencycontact_disability_desc && !emergencycontactdisabilitydesc) setEmergencycontactdisabilitydesc(user.emergencycontact_disability_desc);
            if (user.household_income && householdincome === "none") setHouseholdincome(user.household_income);
            if (user.fundedby && fundedby === "none") setFundedby(user.fundedby);
            if (user.bursary_name && !bursaryname) setBursaryname(user.bursary_name);
            if (user.bursary_obligation && bursaryobligation === "none") setBursaryobligation(user.bursary_obligation);
            if (user.file_AcademicRecord && !fileAcademicRecord) setFileAcademicRecord(user.file_AcademicRecord);
            if (user.file_Acceptance && !fileAcceptance) setFileAcceptance(user.file_Acceptance);
            if (user.file_FeeStatement && !fileFeeStatement) setFileFeeStatement(user.file_FeeStatement);
            if (user.file_Grade_11 && !fileGrade11) setFileGrade11(user.file_Grade_11);
            if (user.file_Grade_12 && !fileGrade12) setFileGrade12(user.file_Grade_12);
            if (user.file_ID && !fileId) setFileId(user.file_ID);
            if (user.file_profilePic && !fileProfilePic) setFileProfilePic(user.file_profilePic);
            if (user.file_ProofRegistration && !fileProofRegistration) setFileProofRegistration(user.file_ProofRegistration);
            if (user.file_ProofConsent && !fileProofConsent) setFileProofConsent(user.file_ProofConsent);
            setPopulated(true);
        }
        setPersonalDetailsValidated(
            gender !== "none" &&
            nationality &&
            race !== "none" &&
            homelanguage !== "none" &&
            otherlanguage !== "none" &&
            addline1 &&
            city &&
            suburb &&
            province !== "none" &&
            postcode
        );
        setContactDetailsValidated(altname && altrelation && altmobile);
        setEducationalDetailsValidated(
            (
                matriculated === "No" &&
                (tertiaryinstitution === "No" || tertiaryinstitution === "none") &&
                gr11subjcnt >= 6 &&
                highschoolprovince !== "none" &&
                highschoolname
            ) ||
            (
                matriculated === "Yes" &&
                (tertiaryinstitution === "No" || tertiaryinstitution === "none") &&
                gr12subjcnt >= 6 &&
                highschoolprovince !== "none" &&
                highschoolname
            ) ||
            (
                matriculated === "Yes" &&
                yearmatriculated !== "none" &&
                tertiaryinstitution === "Yes" &&
                tersubjcnt >= 4 &&
                (tertiaryinstitutionname || otherinstitutionname) &&
                levelstudy !== "none" &&
                yearstudy !== "none" &&
                studentnumber
            )
        );
        setLeadershipRolesValidated(
            true
        );
        setEmergencyContactsValidated(
            emergencycontactname &&
            emergencycontactrelationship &&
            (emergencycontactnumber.match(/^0\d{9}$/) || emergencycontactnumber.match(/^\+\d{11}$/)) &&
            (emergencycontactdisability === "No" || (emergencycontactdisability === "Yes" && emergencycontactdisabilitydesc)) &&
            (emergencycontactconvicted === "No" || (emergencycontactconvicted === "Yes" && emergencycontactconvicteddescn))
        );
        setCurrentObligationsValidated(
            (
                householdincome !== "none" &&
                fundedby !== "Bursary" &&
                fundedby !== "none"
            ) || (
                householdincome !== "none" &&
                fundedby === "Bursary" &&
                bursaryname &&
                bursaryobligation !== "none"
            )
        );
        setSupportingDocumentsValidated(
            fileId &&
            (
                (matriculated === "Yes" && tertiaryinstitution === "Yes" && fileAcademicRecord) ||
                (matriculated === "Yes" && (tertiaryinstitution === "No" || tertiaryinstitution === "none") && fileGrade12) ||
                (matriculated === "No" && (tertiaryinstitution === "No" || tertiaryinstitution === "none") && fileGrade11)
            ) &&
            (
                user.over18 === "yes" || fileProofConsent
            )
        );

        setAllValidated(
            personalDetailsValidated &&
            contactDetailsValidated &&
            educationalDetailsValidated &&
            leadershipRolesValidated &&
            emergencyContactsValidated &&
            currentObligationsValidated &&
            supportingDocumentsValidated
        );
        if (fundedby !== "Bursary") {
            setBursaryname("");
            setBursaryobligation("");
        }
        if (matriculated === "No") {
            setTertiaryinstitution("none");
        }
    }, [props, user, addline1, addline2, city, suburb, province, postcode, gender, nationality, race, homelanguage, otherlanguage,
        altname, altrelation, altmobile, matriculated, highschoolprovince, highschoolname, yearmatriculated, tertiaryinstitution,
        tertiaryinstitutionname, otherinstitutionname, levelstudy, yearstudy, studentnumber,
        gr11subj1, gr11mark1, gr12subj1, gr12mark1, gr11subj2, gr11mark2, gr12subj2, gr12mark2, gr11subj3, gr11mark3, gr12subj3,
        gr12mark3, gr11subj4, gr11mark4, gr12subj4, gr12mark4, gr11subj5, gr11mark5, gr12subj5, gr12mark5, gr11subj6, gr11mark6,
        gr12subj6, gr12mark6, gr11subj7, gr11mark7, gr12subj7, gr12mark7, gr11subj8, gr11mark8, gr12subj8, gr12mark8, gr11subj9,
        gr11mark9, gr12subj9, gr12mark9, gr11subj10, gr11mark10, gr12subj10, gr12mark10, tersubj1, termark1, tersubj2, termark2,
        tersubj3, termark3, tersubj4, termark4, tersubj5, termark5, tersubj6, termark6, extramuralActivities, achievements, careerMotivation,
        emergencycontactname, emergencycontactrelationship, emergencycontactnumber, emergencycontactdisability, emergencycontactconvicted,
        emergencycontactconvicteddescn, emergencycontactdisabilitydesc, householdincome, fundedby, bursaryname, bursaryobligation,
        fileAcademicRecord, fileAcceptance, fileFeeStatement, fileGrade11, fileGrade12, fileId, fileProfilePic, fileProofRegistration,
        fileProofConsent, gr11avg, gr11subjcnt, gr12avg, gr12subjcnt, teravg, tersubjcnt, contactDetailsValidated, currentObligationsValidated,
        educationalDetailsValidated, emergencyContactsValidated, leadershipRolesValidated, personalDetailsValidated, supportingDocumentsValidated, populated
    ]);

    if (Object.keys(user).length === 0) {
        return (<Box />);
    }

    return (
        <Container component="main" sx={{ mt: 3, alignItems: "left" }}  >
            <ThemeProvider theme={theme} >
                <CssBaseline />
                <Typography style={{ textAlign: "left", fontSize: 32, fontWeight: 700 }}>
                    Register to apply
                </Typography>
                <Box>
                    <Box
                        sx={{ p: 4, borderRadius: "16px", color: "#FFFFFF", mt: 2 }}
                        style={{ background: 'linear-gradient(to right, #9C2AA0, #E60000)' }} >
                        <Typography display="inline" sx={{ fontSize: 30 }}>Welcome, </Typography>
                        <Typography display="inline" sx={{ fontSize: 20, fontWeight: 400 }}>{user.firstname} {user.lastname}</Typography>
                    </Box>
                    <Box sx={{ mb: 8 }}>
                        {(expanded === 1) ?
                            <PersonalDetails props={formProps} /> :
                            <Summary props={{ ...formProps, index: 1 }} />
                        }
                        {(expanded === 2) ?
                            <ContactDetails props={formProps} /> :
                            <Summary props={{ ...formProps, index: 2 }} />
                        }
                        {(expanded === 3) ?
                            <EducationalDetails props={formProps} /> :
                            <Summary props={{ ...formProps, index: 3 }} />
                        }
                        {(expanded === 4) ?
                            <LeadershipRoles props={formProps} /> :
                            <Summary props={{ ...formProps, index: 4 }} />
                        }
                        {(expanded === 5) ?
                            <EmergencyContacts props={formProps} /> :
                            <Summary props={{ ...formProps, index: 5 }} />
                        }
                        {(expanded === 6) ?
                            <CurrentObligations props={formProps} /> :
                            <Summary props={{ ...formProps, index: 6 }} />
                        }
                        {(expanded === 7) ?
                            <SupportingDocuments props={formProps} /> :
                            <Summary props={{ ...formProps, index: 7 }} />
                        }
                    </Box>
                </Box>
                <Box sx={{ boxShadow: 3 }} style={{ color: "gray", position: "fixed", bottom: 0, left: 0, right: 0, background: "#FFFFFF", textAlign: "right" }}>
                    <Box sx={{ p: 2 }}>
                        <Button
                            variant="outlined"
                            sx={{ mr: 3 }} style={{ borderRadius: 68, width: 100, fontSize: 16, fontWeight: 700 }}
                            onClick={handleSave}
                        >
                            Save
                        </Button>
                        {(allValidated) ?
                            <Button
                                variant="filled"
                                sx={{ mr: 3 }} style={{ borderRadius: 68, width: 100, fontSize: 16, fontWeight: 700, backgroundColor: 'black', color: 'white' }}
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button> :
                            <Button variant="filled" disabled>
                                Submit
                            </Button>
                        }
                    </Box>
                </Box>
            </ThemeProvider>
        </Container>
    );
}

export default ApplicationForm;