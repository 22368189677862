import * as React from 'react';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CreateIcon from '@mui/icons-material/Create';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider'

function GreenTick() {
    return (<CheckCircleIcon style={{ color: 'green', height: 20 }} />);
}

function PersonalDetailsSummary({ props }) {

    const [title, setTitle] = React.useState("");
    const [fields, setFields] = React.useState([]);
    const [validated, setValidated] = React.useState(false);

    React.useEffect(() => {
        const user = props.user;
        const index = props.index;
        switch (index) {
            case 1:
                setTitle("Personal Details");
                setFields([
                    "Name", user.firstname,
                    "Surname", user.lastname,
                    "ID Number", user.rsaid,
                    "Over 18", (user.over18 === "yes") ? "Yes" : "No",
                    "Cellphone Number", user.mobile,
                    "Email Address", user.email,
                    "Gender", props.gender,
                    "Country of Birth", props.nationality,
                    "Race", props.race,
                    "Home Language", props.homelanguage,
                    "Other Language", props.otherlanguage,
                    "Address Line 1", props.addline1,
                    "Address Line 2", props.addline2,
                    "City", props.city,
                    "Suburb", props.suburb,
                    "Province", props.province,
                    "Postal Code", props.postcode
                ]);
                setValidated(props.personalDetailsValidated);
                break;
            case 2:
                setTitle("Contact Details");
                setFields([
                    "Alternate Contact Person", props.altname,
                    "Alternate Contact Person Relationship", props.altrelation,
                    "Alternate Contact Person Number", props.altmobile
                ]);
                setValidated(props.contactDetailsValidated);
                break;
            case 3:
                setTitle("Educational Details");
                var details = ["Matriculated", props.matriculated];
                if (props.matriculated === "Yes") {
                    details.push("Year Matriculated")
                    details.push(props.yearmatriculated);
                }
                details = details.concat([
                    "High School Name", props.highschoolname,
                    "High School Province", props.highschoolprovince,
                    "In Tertiary Institution", props.tertiaryinstitution
                ]);
                if (props.tertiaryinstitution === "Yes") {
                    details.push("Tertiary Institution Name");
                    details.push(props.otherinstitutionname || props.tertiaryinstitutionname);
                    details = details.concat([
                        "Level of study", props.levelstudy,
                        "Year of study", props.yearstudy,
                        "Student Number", props.studentnumber
                    ]);
                }
                details.push("Course Type");
                details.push(user.coursetype);
                if (props.tertiaryinstitution === "Yes") {
                    details.push("Current Results");
                    details.push("");
                    if (props.tersubj1) details = details.concat([props.tersubj1, props.termark1]);
                    if (props.tersubj2) details = details.concat([props.tersubj2, props.termark2]);
                    if (props.tersubj3) details = details.concat([props.tersubj3, props.termark3]);
                    if (props.tersubj4) details = details.concat([props.tersubj4, props.termark4]);
                    if (props.tersubj5) details = details.concat([props.tersubj5, props.termark5]);
                    if (props.tersubj6) details = details.concat([props.tersubj6, props.termark6]);
                } else {
                    if (props.matriculated === "No") {
                        details.push("Current Results - Grade 11");
                        details.push("");
                        if (props.gr11subj1 !== "none") details = details.concat([props.gr11subj1, props.gr11mark1]);
                        if (props.gr11subj2 !== "none") details = details.concat([props.gr11subj2, props.gr11mark2]);
                        if (props.gr11subj3 !== "none") details = details.concat([props.gr11subj3, props.gr11mark3]);
                        if (props.gr11subj4 !== "none") details = details.concat([props.gr11subj4, props.gr11mark4]);
                        if (props.gr11subj5 !== "none") details = details.concat([props.gr11subj5, props.gr11mark5]);
                        if (props.gr11subj6 !== "none") details = details.concat([props.gr11subj6, props.gr11mark6]);
                        if (props.gr11subj7 !== "none") details = details.concat([props.gr11subj7, props.gr11mark7]);
                        if (props.gr11subj8 !== "none") details = details.concat([props.gr11subj8, props.gr11mark8]);
                        if (props.gr11subj9 !== "none") details = details.concat([props.gr11subj9, props.gr11mark9]);
                        if (props.gr11subj10 !== "none") details = details.concat([props.gr11subj10, props.gr11mark10]);
                    }
                    details.push("Current Results - Grade 12");
                    details.push("");
                    if (props.gr12subj1 !== "none") details = details.concat([props.gr12subj1, props.gr12mark1]);
                    if (props.gr12subj2 !== "none") details = details.concat([props.gr12subj2, props.gr12mark2]);
                    if (props.gr12subj3 !== "none") details = details.concat([props.gr12subj3, props.gr12mark3]);
                    if (props.gr12subj4 !== "none") details = details.concat([props.gr12subj4, props.gr12mark4]);
                    if (props.gr12subj5 !== "none") details = details.concat([props.gr12subj5, props.gr12mark5]);
                    if (props.gr12subj6 !== "none") details = details.concat([props.gr12subj6, props.gr12mark6]);
                    if (props.gr12subj7 !== "none") details = details.concat([props.gr12subj7, props.gr12mark7]);
                    if (props.gr12subj8 !== "none") details = details.concat([props.gr12subj8, props.gr12mark8]);
                    if (props.gr12subj9 !== "none") details = details.concat([props.gr12subj9, props.gr12mark9]);
                    if (props.gr12subj10 !== "none") details = details.concat([props.gr12subj10, props.gr12mark10]);
                }
                setFields(details);
                setValidated(props.educationalDetailsValidated);
                break;
            case 4:
                setTitle("Leadership Roles");
                setFields([
                    "Extramural Activities", props.extramuralActivities,
                    "List Any Achievements", props.achievements,
                    "Career Motivation", props.careerMotivation
                ]);
                setValidated(props.leadershipRolesValidated);
                break;
            case 5:
                setTitle("Emergency Contacts");
                var attrs = [
                    "Emergency Contact Person", props.emergencycontactname,
                    "Emergency Contact Relationship", props.emergencycontactrelationship,
                    "Emergency Contact No.", props.emergencycontactnumber,
                    "Have mental / physical disability", props.emergencycontactdisability,
                ];
                if (props.emergencycontactdisability === "Yes") {
                    attrs.push("Mental/ physical disability description");
                    attrs.push(props.emergencycontactdisabilitydesc);
                }
                attrs.push("Convicted of crime/s");
                attrs.push(props.emergencycontactconvicted);

                if (props.emergencycontactconvicted === "Yes") {
                    attrs.push("Crime/s description");
                    attrs.push(props.emergencycontactconvicteddescn);
                }
                setFields(attrs);
                setValidated(props.emergencyContactsValidated);
                break;
            case 6:
                setTitle("Current Obligations");
                attrs = [
                    "Combined household income", props.householdincome,
                    "Studies currently funded by", props.fundedby
                ];
                if (props.fundedby === "Bursary") {
                    attrs.push("Bursary name");
                    attrs.push(props.bursaryname);
                    attrs.push("Employment obligations");
                    attrs.push(props.bursaryobligation);
                }
                setFields(attrs);
                setValidated(props.currentObligationsValidated);
                break;
            case 7:
                setTitle("Supporting Documents");
                var dattrs = [];
                if (props.fileId) {
                    dattrs.push("ID");
                    dattrs.push(props.fileId);
                }
                if (props.fileGrade11) {
                    dattrs.push("High School Academic Records | Grade 11");
                    dattrs.push(props.fileGrade11);
                }
                if (props.fileGrade12) {
                    dattrs.push("High School Academic Records | Grade 12");
                    dattrs.push(props.fileGrade12);
                }
                if (props.fileProofConsent) {
                    dattrs.push("Parental/ Guardian Consent form");
                    dattrs.push(props.fileProofConsent);
                }
                if (props.fileAcademicRecord) {
                    dattrs.push("Academic records");
                    dattrs.push(props.fileAcademicRecord);
                }

                setFields(dattrs);
                setValidated(props.supportingDocumentsValidated);
                break;
            default:
                setTitle(`Unkown ${props.index}`);
        }
    }, [props]);

    return (
        <Card key={props.index} role="button" sx={{ backgroundColor: "#FFFFFF", mt: 2, borderRadius: "8px" }}>
            <CardActionArea>
                <CardContent sx={{ width: "100%" }}>
                    <Grid sx={{justifyContent: "space-between", alignItems: "center;"}} container columns={18}>
                        <Grid item sm={6} align="left">
                            <Box sx={{ display: "inline-flex" }}>
                                <Box>
                                    {validated && <GreenTick />}
                                </Box>
                                <Box>
                                    <Typography>{title}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item sm={6}>
                            &nbsp;
                        </Grid>
                        <Grid item sm={6} align="right">
                            <Button
                                onClick={() => props.setExpanded(props.index)}
                            >
                                <Box sx={{ display: "inline-flex" }}>
                                    <Box>
                                        <CreateIcon />
                                    </Box>
                                    <Box sx={{ marginLeft: 1 }}>
                                        <Typography>Edit details</Typography>
                                    </Box>
                                </Box>
                            </Button>
                        </Grid>
                    </Grid>
                    <Divider sx={{ mt: 3, mb: 3 }} />
                    <Grid justify="space-between" container columns={16}>
                        {fields.map((value, index) => (
                            <Grid item key={index} xs={8}>
                                {((index + 1) % 2) ?
                                    ((value.startsWith("Current Results")) ? <Typography sx={{ fontWeight: "bold", fontSize: 16 }}>{value}</Typography> : <Typography sx={{ fontWeight: "bold" }}>{value}</Typography>) :
                                    <Typography sx={{}}>{value}</Typography>
                                }
                            </Grid>
                        ))}
                    </Grid>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export default PersonalDetailsSummary;