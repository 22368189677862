import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';

const submitTheme = createTheme({
    palette: {
        primary: {
            main: '#E60000',
        }
    },
    typography: {
        button: {
            textTransform: 'none'
        }
    }
});

function ApplicationProcess({ props }) {

    const handleClick = (event) => {
        props.setCurrentPage("Login");
    };

    return (
        <Box sx={{ m: 10, p: 2 }}>
            <Box sx={{ textAlign: "left" }}>
                <Box sx={{ mb: 2 }}>
                    <Typography sx={{ fontSize: 32, fontWeight: 700, mt: 3 }}>
                        Application Process
                    </Typography>
                </Box>
                <Box>
                    <Box>
                        <Typography sx={{ fontSize: 22, fontWeight: 700, mt: 3 }}>
                            1. Register to Login
                        </Typography>
                        <ul>
                            <li>For registration you will be required to provide your ID number, email, contact number, password, as well as a selection of the course type.</li>
                            <li>A verification email will be sent to you.</li>
                            <li>Once you have selected the verification link in the email sent to you, you will be able to login.</li>
                            <li>Log in requires the capture of your email and password.</li>
                        </ul>
                    </Box>
                    <Box>
                        <Typography sx={{ fontSize: 22, fontWeight: 700, mt: 3 }}>
                            2. Complete the below sections in an online form
                        </Typography>
                        <ul>
                            <li>Personal information</li>
                            <li>Contact details</li>
                            <li>Educational details</li>
                            <li>Leadership roles</li>
                            <li>Current obligations</li>
                            <li>Supporting Documents
                                <ul>
                                    <li>ID</li>
                                    <li>If you are a minor
                                        <ul>
                                            <li>Parental Consent Form</li>
                                        </ul>
                                    </li>
                                    <li>If you have not matriculated yet
                                        <ul>
                                            <li>Grade 11 results</li>
                                            <li>Grade 12 results optional</li>
                                        </ul>
                                    </li>
                                    <li>If you have matriculated but not at tertiary yet
                                        <ul>
                                            <li>Grade 12 results</li>
                                            <li>Acceptance letter</li>
                                        </ul>
                                    </li>
                                    <li>If you have matriculated and at tertiary
                                        <ul>
                                            <li>Academic record</li>
                                            <li>Fee statement</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ textAlign: "center" }}>
                <ThemeProvider theme={submitTheme}>
                    <Button
                        variant="contained"
                        sx={{ mt: 3, mb: 2, width: 200 }}
                        style={{ borderRadius: 68, height: 48, fontSize: 16 }}
                        onClick={handleClick}
                    >
                        Back
                    </Button>
                </ThemeProvider>
            </Box>
        </Box>
    );
}

export default ApplicationProcess;