import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import ErrorBar from '../ErrorBar/ErrorBar';


function Star() {
    return (<span style={{ color: "#E60000" }}>*</span>);
}

const sxFormLabel = { color: "#08081B", fontSize: 16, fontWeight: 700, mb: 1 }

function ContactDetails({ props }) {
    const [error, setError] = React.useState("");

    const handleNext = (ev) => {
        if (!props.contactDetailsValidated) {
            setError("Please complete mandatory fields");
        } else {
            props.updateBursar();
            props.setExpanded(3);
        }
    };

    return (
        <Box sx={{ backgroundColor: "#FFFFFF", padding: 2, borderRadius: 2, mt: 3, boxShadow: 3 }}>
            {error &&
                <ErrorBar props={{ error: error, setError: setError }} />
            }
            <Grid container columns={16} sx={{ color: "#282828" }}>
                <Grid item sm={8} sx={{ textAlign: "left" }}>
                    <Typography sx={{ fontSize: 18, fontWeight: 500 }}>
                        Contact Details
                    </Typography>
                </Grid>
                <Grid item sm={8} sx={{ textAlign: "right" }}>
                    <Typography sx={{ color: "#666666", fontSize: 16, fontWeight: 500 }}>
                        Section 2 of {props.sections}
                    </Typography>
                </Grid>
            </Grid>
            <Divider sx={{ mt: 3, mb: 3 }} />
            <Grid container columns={16} spacing={3} padding={2}>
                <Grid item sm={8} sx={{width: "100%"}}>
                    <FormControl fullWidth>
                        <FormLabel id="label-altname" sx={sxFormLabel}>Alternate Contact Person <Star /></FormLabel>
                        <TextField
                            required
                            fullWidth
                            error={props.altname ? false : true}
                            label="Enter name"
                            value={props.altname}
                            onChange={(ev) => { props.setAltname(ev.target.value) }}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={8} sx={{width: "100%"}}>
                    <FormControl fullWidth>
                        <FormLabel id="label-altrelation" sx={sxFormLabel}>Alternate Contact Person Relationship<Star /></FormLabel>
                        <TextField
                            required
                            fullWidth
                            error={props.altrelation ? false : true}
                            label="Enter relationship"
                            value={props.altrelation}
                            onChange={(ev) => { props.setAltrelation(ev.target.value) }}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={8} sx={{width: "100%"}}>
                    <FormControl fullWidth>
                        <FormLabel id="label-altmobile" sx={sxFormLabel}>Alternate Contact Person Number<Star /></FormLabel>
                        <TextField
                            required
                            fullWidth
                            error={!(props.altmobile.match(/^0\d{9}$/) || props.altmobile.match(/^\+\d{11}$/))}
                            label="Enter contact number"
                            value={props.altmobile}
                            onChange={(ev) => { props.setAltmobile(ev.target.value) }}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={8} sx={{width: "100%"}}>
                    &nbsp;
                </Grid>
            </Grid>
            <Box sx={{ width: "100%" }} align="right">
                <Button
                    variant="filled"
                    sx={{ mr: 3 }} style={{ borderRadius: 68, width: 100, fontSize: 16, fontWeight: 700, backgroundColor: 'black', color: 'white' }}
                    onClick={handleNext}
                >
                    Next
                </Button>
            </Box>
        </Box>
    );
}


export default ContactDetails;