import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button';
import ErrorBar from '../ErrorBar/ErrorBar';

const sxFormLabel = { color: "#08081B", fontSize: 16, fontWeight: 700, mb: 1 }

function LeadershipRoles({ props }) {
    const [error, setError] = React.useState("");

    const handleNext = (ev) => {
        if (!props.leadershipRolesValidated) {
            setError("Please complete mandatory fields");
        } else {
            props.updateBursar();
            props.setExpanded(5);
        }
    };
    return (
        <Box sx={{ backgroundColor: "#FFFFFF", padding: 2, borderRadius: 2, mt: 3, boxShadow: 3 }}>
            {error &&
                <ErrorBar props={{ error: error, setError: setError }} />
            }
            <Grid container columns={16} sx={{ color: "#282828" }}>
                <Grid item sm={8} sx={{ textAlign: "left" }}>
                    <Typography sx={{ fontSize: 18, fontWeight: 500 }}>
                        Leadership Roles
                    </Typography>
                </Grid>
                <Grid item sm={8} sx={{ textAlign: "right" }}>
                    <Typography sx={{ color: "#666666", fontSize: 16, fontWeight: 500 }}>
                        Section 4 of {props.sections}
                    </Typography>
                </Grid>
            </Grid>
            <Divider sx={{ mt: 3, mb: 3 }} />
            <Grid container columns={16} spacing={3} padding={2}>
                <Grid item sm={16} sx={{width: "100%"}}>
                    <FormControl fullWidth>
                        <FormLabel id="label-extramuralactivities" sx={sxFormLabel}>Extramural Activities</FormLabel>
                        <TextField
                            multiline
                            rows={4}
                            fullWidth
                            label="Enter extramural activities"
                            value={props.extramuralActivities}
                            onChange={(ev) => { props.setExtramuralActivities(ev.target.value) }}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={16} sx={{width: "100%"}}>
                    <FormControl fullWidth>
                        <FormLabel id="label-achievements" sx={sxFormLabel}>List Any Achievements</FormLabel>
                        <TextField
                            multiline
                            rows={4}
                            fullWidth
                            label="Enter list of achievments"
                            value={props.achievements}
                            onChange={(ev) => { props.setAchievements(ev.target.value) }}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={16} sx={{width: "100%"}}>
                    <FormControl fullWidth>
                        <FormLabel id="label-career-motivation" sx={sxFormLabel}>Career Motivation</FormLabel>
                        <TextField
                            multiline
                            rows={4}
                            fullWidth
                            label="Enter career motivation"
                            value={props.careerMotivation}
                            onChange={(ev) => { props.setCareerMotivation(ev.target.value) }}
                        />
                    </FormControl>
                </Grid>
                <Grid item sm={8}>
                    &nbsp;
                </Grid>
            </Grid>
            <Box sx={{ width: "100%" }} align="right">
                <Button
                    variant="filled"
                    sx={{ mr: 3 }} style={{ borderRadius: 68, width: 100, fontSize: 16, fontWeight: 700, backgroundColor: 'black', color: 'white' }}
                    onClick={handleNext}
                >
                    Next
                </Button>
            </Box>
        </Box>
    );
}


export default LeadershipRoles;