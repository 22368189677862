import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const submitTheme = createTheme({
    palette: {
        primary: {
            main: '#E60000',
        }
    },
    typography: {
        button: {
            textTransform: 'none'
        }
    }
});

const GradientInfoIcon = () => (
    <>
        <svg width={0} height={0}>
            <linearGradient id="linearColors" x1={0} y1={1} x2={1} y2={1}>
                <stop offset={0} stopColor="rgba(168, 180, 0, 1)" />
                <stop offset={1} stopColor="rgba(0, 176, 202, 1)" />
            </linearGradient>
        </svg>
        <InfoIcon sx={{ fill: "url(#linearColors)", fontSize: "80px" }} />
    </>
)

function PasswordUpdated({ props }) {

    const handleClick = (event) => {
        props.setCurrentPage("Login");
    };

    return (
        <Box sx={{ height: "100vh", display: "flex", alignItems: "center" }} justifyContent="center">
            <Box sx={{ textAlign: "center" }}>
                <Box>
                    <Typography>
                        <GradientInfoIcon />
                    </Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontSize: 32, fontWeight: 700, mt: 3 }}>
                        Password reset successful
                    </Typography>
                </Box>
                <Box>
                    <Typography sx={{ fontSize: 14, mt: 3 }}>
                        Your password have successfully been reset
                    </Typography>
                </Box>
                <Box>
                    <ThemeProvider theme={submitTheme}>
                        <Button
                            variant="contained"
                            sx={{ mt: 3, mb: 2, width: 200 }}
                            style={{ borderRadius: 68, height: 48, fontSize: 16, width: "100%" }}
                            onClick={handleClick}
                        >
                            Back to Login
                        </Button>
                    </ThemeProvider>
                </Box>
            </Box>
        </Box>
    );
}

export default PasswordUpdated;