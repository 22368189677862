import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import MenuItem from '@mui/material/MenuItem';
import { createTheme } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import { ThemeProvider } from '@emotion/react';
import LogoutIcon from '@mui/icons-material/Logout';
import logo from './assets/logo120.png';
import ErrorBar from './components/ErrorBar/ErrorBar'
import SuccessBar from './components/SuccessBar/SuccessBar'
import WaitBar from './components/WaitBar/WaitBar'


const theme = createTheme({
    palette: {
        primary: {
            main: '#FFFFFF',
        },
        secondary: {
            main: green[500],
        }
    },
    typography: {
        button: {
            textTransform: 'none'
        }
    }
});

function ResponsiveAppBar({ props }) {

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [pages, setPages] = React.useState([]);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = (page) => {
        props.setCurrentPage(page);
        setAnchorElNav(null);
    };

    const handleLogout = () => {
        localStorage.removeItem("accessToken");
        props.setLoggedIn(false);
        props.setCurrentPage("Login");
    }

    React.useEffect(() => {
        if (props.loggedIn) {
            setPages(['Application Process', 'Terms & Conditions', 'Application Form']);
        } else {
            setPages(['Login', 'Application Process', 'Terms & Conditions']);
        }
    }, [props]);

    return (
        <ThemeProvider theme={theme}>
            <AppBar position="sticky">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <img style={{ width: 52, height: 52 }} src={logo} alt="logo" />
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end' }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={() => handleCloseNavMenu(props.currentPage)}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {pages.map((page) => (
                                    <MenuItem key={page} onClick={() => handleCloseNavMenu(page)}>
                                        <Typography textAlign="center">{page}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        <Box sx={{ ml: 3, flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {pages.map((page) => (
                                <Button
                                    value={page}
                                    key={page}
                                    onClick={() => handleCloseNavMenu(page)}
                                    sx={{ my: 2, color: (props.currentPage === page) ? '#E60000' : '#08081B', display: 'block', fontWeight: 700, fontSize: 14, }}
                                >
                                    {page}
                                </Button>
                            ))}
                        </Box>
                        {props.loggedIn &&
                            <Box><IconButton onClick={handleLogout}><LogoutIcon /></IconButton></Box>
                        }
                        {(!props.loggedIn && !props.applicationsClosed) &&
                            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
                                <ButtonBase
                                    key="Register"
                                    value="Register"
                                    onClick={() => handleCloseNavMenu("Register")}
                                    sx={{
                                        my: 2, display: 'block',
                                        color: "#FFFFFF",
                                        padding: "12px 16px",
                                        height: 48,
                                        width: 150,
                                        borderRadius: 68,
                                        backgroundColor: "#E60000"
                                    }}
                                >
                                    Register
                                </ButtonBase>
                            </Box>
                        }
                    </Toolbar>
                </Container>
            </AppBar>
            <Box sx={{position: "fixed", top: 65, width: "100%", zIndex:1500}}>
                    {props.error ? <ErrorBar props={{ error: props.error, setError: props.setError }} /> : <Container sx={{ mt: 0.5 }} />}
                    {props.successMessage ? <SuccessBar props={{ successMessage: props.successMessage, setSuccessMessage: props.setSuccessMessage }} /> : <Container sx={{ mt: 0.5 }} />}
                    {props.waiting ? <WaitBar /> : <Container sx={{ mt: 0.5 }} />}
            </Box>
        </ThemeProvider>
    );
}
export default ResponsiveAppBar;