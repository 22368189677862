import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';

const submitTheme = createTheme({
    palette: {
        primary: {
            main: '#E60000',
        }
    },
    typography: {
        button: {
            textTransform: 'none'
        }
    }
});

function TermsAndConditions({ props }) {
    const [terms, setTerms] = React.useState([]);

    const handleClick = (event) => {
        props.setCurrentPage("Login");
    };


    React.useEffect(() => {
        if (props.activeBursary) {
            props.setWaiting(false);
            const formattedTerms = props.activeBursary.Terms.split("\n");
            setTerms(formattedTerms);
        } else {
            props.setWaiting(true);
        }
    }, [props]);

    return (
        <Box sx={{ m: 2, p: 2 }}>
            <Box sx={{ textAlign: "left" }}>
                <Box sx={{ mb: 2 }}>
                    <Typography sx={{ fontSize: 32, fontWeight: 700, mt: 3 }}>
                        Terms & Conditions
                    </Typography>
                </Box>
                <Box sx={{ backgroundColor: "#F5F6F7", p: 2, borderRadius: 2 }}>
                {terms.map((line, index) => (
                    <Typography key={index} sx={{ fontSize: 14 }}>
                       {line}
                    </Typography>
                    ))}
                </Box>
            </Box>
            <Box sx={{textAlign: "center"}}>
                <ThemeProvider theme={submitTheme}>
                    <Button
                        variant="contained"
                        sx={{ mt: 3, mb: 2, width: 200 }}
                        style={{ borderRadius: 68, height: 48, fontSize: 16 }}
                        onClick={handleClick}
                    >
                        Back
                    </Button>
                </ThemeProvider>
            </Box>
        </Box>
    );
}

export default TermsAndConditions;